import type { NextPage } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

const Home: NextPage = () => {
  const router = useRouter();
  React.useEffect(() => {
    router.push('/account');
  }, []);
  return (
    <div>
      <Head>
        <title>Mental | How dudes strengthen their mindset</title>
        <meta name="description" content="Mental | How dudes strengthen their mindset" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="preload" href="/fonts/Nexa/Nexa-Bold.otf" as="font" crossOrigin="anonymous" />
      </Head>

      <main className="bg-mental-brand flex flex-col h-screen w-full">
        <div className="w-full pt-16 sm:pt-28">
          <h1>
            {/* eslint-disable-next-line */}
            <img className="w-[300px] sm:w-[420px] m-auto" alt="mental" src="/images/wordmark.png" />
          </h1>
        </div>
      </main>
    </div>
  );
};

export default Home;
